import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { RichTextComponent } from '@msdyn365-commerce/core';
import { IKrannichPortalConfig, IKrannichPortalResources } from './krannich-portal.props.autogenerated';

interface ModalProps {
    isOpen: boolean;
    config: IKrannichPortalConfig;
    resources: IKrannichPortalResources;
    inputRef: any;
    zipCode: string;
    setZipCode: React.Dispatch<React.SetStateAction<string>>;
    error: string;
    alertActive: boolean;
    _handleZipCode: (event: React.FormEvent) => Promise<void>;
    modalBtn: any;
    isPreviewUrl: boolean;
}

const PortalModal: React.FC<ModalProps> = props => {
    const { alertActive, config, error, inputRef, isOpen, modalBtn, resources, setZipCode, _handleZipCode, zipCode, isPreviewUrl } = props;
    // const isPreviewUrlEnabled = config.enablePreviewUrl &&;
    return (
        <div className='container'>
            <Modal
                autoFocus
                fade={false}
                returnFocusRef={modalBtn}
                isOpen={(isOpen && !isPreviewUrl) || (isOpen && config.enablePortalForPreviewUrl)}
                className='city-selector'
                horizontalPosition='right'
                verticalPosition='top'
            >
                <ModalHeader>
                    <div className='logo-wrapper'>
                        <img src={config.logoImage?.src} alt={config.logoImage?.altText} />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <h2>{config.headingWebShop}</h2>
                    <div className='news-letter-sub-area'>
                        <form name='portal-router' onSubmit={_handleZipCode}>
                            <label htmlFor='portalZipCode'>{config.zipCodeLabel}</label>
                            {config.newZealandZipCodeMsg && <p className='info-text'>{config.newZealandZipCodeMsg}</p>}
                            <input
                                type='text'
                                id='portalZipCode'
                                ref={inputRef}
                                value={zipCode}
                                name='zipCode'
                                placeholder='Example: 000000'
                                onChange={e => setZipCode(e.target.value)}
                                maxLength={10}
                            />
                            {alertActive && <p className='error'>{error}</p>}
                            <button
                                className='btn msc-btn empty-btn'
                                onClick={() => {
                                    setZipCode(inputRef.current?.value!);
                                }}
                                type='submit'
                            >
                                {resources.submitBtnLabel}
                            </button>
                        </form>
                    </div>
                    <div className='info-text'>
                        <RichTextComponent text={config.infoText} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <RichTextComponent text={config.footerContent} />
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default PortalModal;
